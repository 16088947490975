<template>
  <div>
    <!-- <confirm-delete
      ref="modal"
      :msg="deleteMsg"
      :title="titleMsg"
      @on-submit="deleteUser"
    /> -->
      <div>
        <b-row class="match-height">
          <b-col lg="4" md="12">
            <statistic-card-horizontal
              :is-loaded="true"
              icon="LayersIcon"
              color="success"
              :statistic="Number(totalWL)"
              statistic-title="Total White Labels"
            />
          </b-col>
          <b-col lg="4" md="12">
            <statistic-card-horizontal
              :is-loaded="true"
              icon="UsersIcon"
              color="success"
              :statistic="totalCustomer"
              statistic-title="Total Customers"
            />
          </b-col>
          <b-col lg="4" md="12">
            <statistic-card-horizontal
              :is-loaded="true"
              icon="BookIcon"
              color="warning"
              :statistic="totalBooking"
              statistic-title="Total Bookings"
            />
          </b-col>
        </b-row>
      </div>
      <b-alert
        variant="danger"
        show
      >
        <div class="alert-body">
          <span class="alert-text">
            <strong>
              Please Note:-
            </strong>
            <small>After new whitelabel creation, Config setting must be added in backend before use <strong>Login As Club</strong> feature.</small>
          </span>
        </div>
      </b-alert>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            md="4"
            lg="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>
           <!-- Search -->
          <b-col lg="5" md="4">
            <div class="d-flex align-items-center mb-1 mb-md-0">
              <label>Search</label>
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 mx-50"
                placeholder="Search by whitelabel name"
              />
            </div>
          </b-col>
          <b-col lg="3" md="4">
            <div class="d-flex align-items-center justify-content-end">
             <b-button variant="primary" @click="$router.push('add')">
               <feather-icon
                  icon="PlusIcon"
                  size="18"
                  style="margin: -2px 0px;"
                /> 
                <strong>Add Club</strong>
             </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        v-if="!contentLoading"
        ref="refWhitelabelListTable"
        class="position-relative"
        :items="items"
        responsive
        :fields="tableColumns"
        :tbody-tr-class="rowClass"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(actions)="data">
          <router-link
            v-b-tooltip.hover.bottom="`Edit Whitelabel`"
            :to="{ name: 'whitelabel-edit', params: { id: data.item.id } }">
            <feather-icon
              size="20"
              varient="success"
              icon="EditIcon" />
            <span class="align-middle ml-50"></span>
          </router-link>
          <button 
            class="btn"
            v-b-tooltip.hover.bottom="`Login as Club`"
            @click="logInAsClub(data.item.userId, data.item.id)">
            <feather-icon
              size="20"
              varient="success"
              icon="LogInIcon" />
            <span class="align-middle ml-50"></span>
          </button>
        </template>
      </b-table>
      <div v-if="contentLoading">
        <Loader />
      </div>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <!-- <span class="text-muted">Showing  of {{ totalUsers }} entries</span> -->
            <span class="text-muted">Showing {{ totalWhitelabel >= 1 ? startIndex + 1 : startIndex }} to
              {{ endIndex > totalWhitelabel ? totalWhitelabel : endIndex }} of
              {{ totalWhitelabel }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :per-page="perPage"
              :total-rows="totalWhitelabel"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @change="pageChange"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BCardHeader,
  BCardBody,
  BFormInput,
  VBTooltip,
  BAlert,
} from "bootstrap-vue";
import vSelect from "vue-select";
import {
  resolveUserRoleIcon,
  resolveUserRoleVariant,
  resolveUserStatusVariant,
  resolveUserRoleName,
  resolveUserStatusName,
  perPageOptions,
  perPage,
  currentPage,
  customerFilter,
  deleteConfirmMsg,
  RoleEnum,
  resConditionCheck
} from "@/helpers/constant";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'
import errorResponseHandler from "@/services/errorHandler";
import checkLoginRole from "@/helpers/checkLoginRole";
import accessRightCheck from "@/helpers/accessRightCheck";
import Loader from "@/layouts/skeloton-loader/Loader.vue";
import WhiteLabelService from '@/services/whitelabel/whitelabel.service'
// import ConfirmDelete from "../../../confirm-delete/ConfirmDelete.vue";
import axios from 'axios'

export default {
  name : "WhitelabelList",
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    // ConfirmDelete,
    BCardHeader,
    BCardBody,
    Loader,
    BFormInput,
    VBTooltip,
    StatisticCardHorizontal,
    BAlert,
  },
  data() {
    return {
      contentLoading: true,
      searchQuery: "",
      sortBy: "",
      isSortDirDesc: true,
      totalWhitelabel: 0,
      totalWL: 0,
      totalCustomer: 0,
      totalBooking: 0,
      items: [],
      startIndex: 0,
      endIndex: 0,
      checkAccess: {},
      checkLoginRole,
      RoleEnum,
      tableColumns: [
        { key: "name", label: "club name", sortable: true },
        { key: "email", sortable: true },
        { key: "country" },
        { key: "actions" }
      ],
      currentPage,
      perPage,
      perPageOptions,
      customerFilter,
      resolveUserRoleIcon,
      resolveUserRoleVariant,
      resolveUserRoleName,
      deleteMsg: "",
      titleMsg: "",
      toggleModal: false,
    };
  },
  directives: {
    "b-tooltip": VBTooltip
  },
  watch: {
    currentPage: {
      handler() {
        this.provider();
      }
    },
    perPage: {
      handler() {
        this.currentPage = 1;
        this.provider();
      }
    },
    sortBy: {
      handler(newValue) {
        // this.sortBy = newValue === "user" ? "firstName" : newValue;
        // this.provider();
      }
    },
    isSortDirDesc: {
      handler() {
        this.provider();
      }
    },
    searchQuery: {
      handler() {
        this.provider();
      }
    },
  },
  mounted() {
    setTimeout(()=> this.totalWL = this.totalWhitelabel,1000)
    // this.checkAccess = accesRightCheck(this.$route.meta.module.name);
    // if (
    //   this.checkLoginRole() !== RoleEnum.SuperAdmin
    //   && !this.checkAccess.Edit && !this.checkAccess.delete
    // ) {
    //   this.tableColumns = [
    //     { key: "user", sortable: true },
    //     { key: "email", sortable: true },
    //     { key: "role" },
    //     { key: "phoneNumber" },
    //     { key: "joinedon" },
    //     { key: "newsletter" },
    //     { key: "deactivated" },
    //     { key: "status" }
    //   ];
    // }

    this.provider();
  },
  
  computed: {
    rowClass(item, type) {
      if (item && type === 'row') {
        // eslint-disable-next-line consistent-return
        if (item.isDeleted) return 'table-danger'
        else return null
      } else {
        return null
      }
    },
  },

  methods: {
    pageChange() {
      window.scrollTo(0, 0);
    },

    provider() {
      this.contentLoading = true
      const payload = {
        no_of_result: this.perPage,
        page: this.currentPage,
        order: this.isSortDirDesc === true ? "DESC" : "ASC",
        order_by: this.sortBy,
        search: this.searchQuery
      };
      WhiteLabelService.getWhitelableList(payload)
        .then(res => {
          if (resConditionCheck(res.status) && res.data.data) {
            this.contentLoading = false;
            this.items = res.data.data;
            this.totalWhitelabel = res.data.count;
            this.totalCustomer = res.data.total_customer ? res.data.total_customer : 0;
            this.totalBooking = res.data.total_booking ? res.data.total_booking : 0;
            this.startIndex = this.currentPage * this.perPage - this.perPage;
            this.endIndex = this.startIndex + this.perPage;
          }
        })
        .catch(error => {
          const errorData = errorResponseHandler(error);
          this.contentLoading = false;
          if (error.response.status === 404) {
            this.items = [];
          } else {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorData,
                icon: "X-CircleIcon",
                variant: "danger"
              }
            });
          }
        });
    },

    logInAsClub(id, wl_code) {
      WhiteLabelService.signInAsWLUser({ user_id: id }, wl_code)
      .then(res => {
        if (resConditionCheck(res.status) && res.data.user_details) {
          localStorage.setItem("signinWLAdmin", true);
          localStorage.setItem("whitelabel_code", wl_code);
          this.$store.commit("auth/SET_TOKEN", res.data.user_details.token);
          this.$store.commit("auth/SET_USER", res.data.user_details);

          window.location.href = "/";

          this.$toast({
            component: ToastificationContent,
            props: {
              title: res.data.message,
              icon: "CheckIcon",
              variant: "success"
            }
          });
        }
      })
      .catch(error => {
        const errorData = errorResponseHandler(error);
        axios.defaults.headers.wl_code = 1
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Whitelabel Config not found, Must be added from backend.',
            icon: "X-CircleIcon",
            variant: "danger"
          }
        });
      });
    },
  }
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
